import React, { useState, useEffect } from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import imageData from './beforeAndAfter.json';  // Ensure correct path

const ImageComparisonGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState({
    firstImage: { imageUrl: imageData[0].before },
    secondImage: { imageUrl: imageData[0].after }
  });

  useEffect(() => {
    setCurrentImages({
      firstImage: { imageUrl: imageData[currentIndex].before },
      secondImage: { imageUrl: imageData[currentIndex].after }
    });
  }, [currentIndex]);

  const nextPair = () => {
    setCurrentIndex((currentIndex + 1) % imageData.length);
  };

  const previousPair = () => {
    setCurrentIndex((currentIndex - 1 + imageData.length) % imageData.length);
  };

  return (
    <div style={{ width: '100vw', top: '55px', height: '100vh', position: 'relative', overflow: 'hidden' }}>
      <ReactBeforeSliderComponent
        firstImage={currentImages.firstImage}
        secondImage={currentImages.secondImage}
        delimiterColor="#fff"
        currentPercentPosition={50}
        withResizeFeel={true}
        style={{
          backgroundImage: `url(${currentImages.firstImage.imageUrl})`,
          backgroundColor: 'black',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100vw',
          height: '100vh',
          position: 'relative'
        }}
      />
      <div style={{
        position: 'absolute',
        bottom: '100px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10
      }}>
        <button onClick={previousPair} style={{ marginRight: '20px' }}>Previous</button>
        <button onClick={nextPair}>Next</button>
      </div>
    </div>
  );
};

export default ImageComparisonGallery;
