import React from "react";
import ContactForm from "./ContactForm";

const Contact = () => {
    return (
        <div className="bg-white p-8 lg:p-16 rounded-lg mx-auto" style={{
          color: 'rgba(0, 0, 0, 0.6)',
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '100px',
          width: '100%', // Ensure the container is responsive
          maxWidth: '1200px', // Increase max width for larger screens
        }}>
            <section className="space-y-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-gray-600 justify-center items-center">
                    <div className="order-2 md:order-1">
                        <h3 className="text-2xl font-semibold mb-4">GNUCHEV & PARTNERS LLC</h3>
                        <p className="mb-2">706 Santa Susana St.</p>
                        <p className="mb-2">Sunnyvale, CA</p>
                        <p className="mb-2">650 810 37 93</p>
                        <p className="mb-2">landscape@gnuchev.pro</p>
                    </div>
                    <div className="order-1 md:order-2 flex justify-center w-full" style={{
          paddingRight: '30px',
          paddingLeft: '30px',
          marginTop: '20px',
        }}>
                        <ContactForm />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;
