import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'; // Import slide show styles
import architectureImageData from './architectureImageData.json';
import landscapeImageData from './landscapeImageData.json';
import recentImageData from './recentImageData.json';
import '../App.css'; // Make sure to import your css file

const Gallery = () => {
  const location = useLocation();
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    if (location.pathname.includes('/architecture')) {
      setImageData(architectureImageData);
    } else if (location.pathname.includes('/landscape')) {
      setImageData(landscapeImageData);
    } else if (location.pathname.includes('/recent-project')) {
      setImageData(recentImageData);
    } else {
      setImageData(recentImageData); // Default fallback
    }
  }, [location.pathname]);

  const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px',
    margin: '0.5rem',
};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
    nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
}

  return (
    <div className="relative fixed inset-0">
      <Slide easing="ease" autoplay="true" {...properties} duration={3000}>
        {imageData.map((image, index) => (
          // Wrap each image and its caption in a div
          <div key={index} className="each-slide flex justify-center items-bottom w-full h-full">
            <div
              style={{
                backgroundImage: `url(${image.fileName})`,
                backgroundColor: 'black', // Fallback background color
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                top: 0,
                width: '100vw',
                height: '100vh',
                position: 'relative', // Ensure positioning context for the caption
                zIndex: -2 // Ensure this is above the grid pattern but adjust as needed
              }}
            >
              {/* Place caption directly within each slide's main div */}
              <div className="bottom-0" style={{
                position: 'absolute', // Ensure positioning context for the caption
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '10%',
                zIndex: 10 // Ensure this is above the grid pattern but adjust as needed
              }}>
              <div className="w-full text-center p-4" style={{
                // background: 'rgba(0, 0, 0, 0.6)',
                zIndex: 20, // Ensure this is above the slide images but adjust as needed
                }}>
                <h4 className="text-white" style={{fontSize: '0.75rem', padding: '0'}}>{image.title}</h4>
                <p className="text-white" style={{fontSize: '0.75rem', padding: '0'}}>{image.description}</p>
              </div>
              </div>
            </div>
          </div>
        ))}
      </Slide>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        //backgroundImage: `url("data:image/svg+xml,%3Csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='none'/%3E%3Cpath d='M 1 2 L 2 1 2 1' stroke='%23000' stroke-width='0.5' opacity='0.25' /%3E%3C/svg%3E")`,
        backgroundSize: 'auto', // Adjust this value to control the size of your grid pattern
        pointerEvents: 'none', // Allows interaction with elements underneath
        zIndex: 1 // Ensure this is above the slide images but adjust as needed
      }}></div>
      
    </div>
  );
};

export default Gallery;
